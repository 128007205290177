//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'

import BTN_CONFIG from './config/btn-list'

export default {
    mixins: [ LIST_MIXIN ],
    props: [],
    data () {
        return {
            tableData: [],
			pgData:{
				proname:'' ,//项目名称
				xmlx:'' ,//项目类型
				shstate: '',
				onlyshowhistory: false,
			},

            btnConfig: BTN_CONFIG,

            loadingController: false
        }
    },
    computed: {

    },
    methods: {

    },
    mounted: function() {

    }
}
