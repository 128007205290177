//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FORM_PAGE_MIXIN from '@mixins/form-page'

export default {
    mixins: [ FORM_PAGE_MIXIN ],
    components: {},
    props: {
        row: {
            type: Object,
            default: () => ({})
        },
        bsk: {
            type: Boolean,
            default: false
        },
        dialog: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        form: {
            shyj: '',
            // isbsk: true
        }
    }),
    computed: {
        detailExtra() {
            return {
                rowguid: this.getQuery('rowguid')
            };
        }
    },
    methods: {
        saveHandler(type, cb) {
            cb && cb();
        },
        beforeSend(send, deny) {
            send();
        },
        cancleHandler() {
            this.$router.go(-1);
        }
    },
    created() {

    }
}
